<template>
  <div class="bg-white d-flex flex-column align-center mt-6">
    <v-card class="price_card">
      <v-alert
          variant="outlined"
          type="warning"
          prominent
          border>A megbeszélt időpontra kérjük, pontosan érkezzenek. Ha a megbeszélt időpont mégsem bizonyul megfelőnek, úgy kérkük, hogy legalább egy nappal a tervezett beavatkozás előtt <span class="font-weight-bold">SMS</span> formájában legyenek szívesek lemondani. Ennek elmulasztása esetén 10000 Ft rendelkezésre állási díjat számolunk fel a következő kezelés alkalmával. Megértésüket köszönjük!</v-alert>
      <v-table>
        <thead>
        <tr>
          <th class="text-left">
            Fogászati szolgáltatás
          </th>
          <th class="text-left">
            Ár (2025.január 1-től)
          </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="item in treatment"
                  :key="item.name">
          <tr class="grouped text-center">
            <td>{{ item.name }}</td>
            <td></td>
          </tr>
          <tr
              v-for="category in item.category"
              :key="category.name"
          >
            <td>{{ category.name }}</td>
            <td class="price">{{ category.price }} {{ category.unit }}</td>
          </tr>
        </template>
        </tbody>
      </v-table>

      <p class="guarantee ma-4">Munkáinkra minden esetben 1 év garanciát vállalunk. Cirkóniumból készült munkák esetében 2 év. Garancia
        érvényesítésének feltétele az ajánlott féléves kontrollon való megjelenés.</p>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Price",
  data() {
    return {
      treatment: [
        {
          name: 'Kontroll vizsgálat',
          category: [
            {name: 'Ajánlott éves kontroll vizsgálat', price: 'Ingyenes', unit: ''},
            {name: 'Szakvélemény, terhességi kontroll, góckutatás', price: '15000', unit: 'Ft'},
          ],
        },
        {
          name: 'Tömések, konzerváló fogászati kezelések',
          category: [
            {name: 'Barázdazárás/tejfog tömés', price: '15000', unit: 'Ft'},
            {name: 'Esztétikus tömés fogtól és felszíntől függően', price: '23000-30000', unit: 'Ft'},
            {name: 'Fogfelépítés üvegszálas csap behelyezése', price: '15000', unit: 'Ft'},
            {name: 'Parapulpális csap', price: '5000', unit: 'Ft'},
            {name: 'Direkt héj', price: '35000', unit: 'Ft'},
            {name: 'Sínezés kompozittal', price: '8000', unit: 'Ft/fog'},
            {name: 'Kompozit inlay (Gradia)', price: '45000', unit: 'Ft'},
          ],
        },
        {
          name: 'Gyökérkezelések',
          category: [
            {name: 'Gyökérkezelés megkezdése (csatornák számától függően)', price: '20000-30000', unit: 'Ft'},
            {
              name: 'Gyökércsatornák feltárása, megmunkálása (Niti gépi eszközökkel), fertőtlenítése ("átmosás")',
              price: '20000-25000',
              unit: 'Ft'
            },
            {name: 'Gyökértömés készítése', price: '20000-30000', unit: 'Ft'},
            {name: 'Röntgenfelvétel', price: '2500', unit: 'Ft/db'}
          ],
        },
        {
          name: 'Foghúzás, fogeltávolítás',
          category: [
            {name: 'Foghúzás esettől függően', price: '20000 - 30000', unit: 'Ft'},
            {name: 'Fogeltávolítás utáni sebtisztítás (fertőtlenítés)', price: '10000', unit: 'Ft/alkalom'},
          ],
        },
        {
          name: 'Rögzitett fogpótlások',
          category: [
            {name: 'Fémkerámia korona és hídtag', price: '45000', unit: 'Ft'},
            {name: 'Fémmentes korona(Cirkónium)', price: '75000', unit: 'Ft/tag'},
            {name: 'Fémmentes E-Max korona', price: '85000', unit: 'Ft/db'},
            {name: 'Technikai (tartósabb) ideiglenes korona', price: '18000', unit: 'Ft/tag'},
            {name: 'Korona eltávolítása/visszaragasztása', price: '1. pillér 10000', unit: 'Ft, további pillérek 5000 Ft/db'},
            {name: 'Implantátumon elhorgonyzott fémkerámiai korona', price: '135000', unit: 'Ft'},
          ],
        },
        {
          name: 'Kivehető fogpótlások/állcsont',
          category: [
            {name: 'Kivehető akril fogsor', price: '150000', unit: 'Ft'},
            {name: 'Fémvázas fogsor', price: '160000', unit: 'Ft'},
            {name: 'Éjszakai harapásemelő (darabonként)', price: '25000', unit: 'Ft'},
            {name: 'ThermoSense fogsor', price: '170000', unit: 'Ft'},
            {name: 'Akril fogsorba súlynövelő/háló ', price: '15000', unit: 'Ft'},
            {name: 'Akril fogsor alábélelése', price: '25000', unit: 'Ft'},
            {name: 'Akril fogsor alábélelése javítása', price: '25000', unit: 'Ft'},
          ],
        },
        {
          name: 'Szájhigénia, megelőző és esztétikai kezelések',
          category: [
            {name: 'Fogkő eltávolítása', price: '23000', unit: 'Ft/alkalom'},
            {name: 'Fogak polírozása intraorális homokfúvással (Air-Flow)', price: '10000', unit: 'Ft/ív'},
            {name: 'Fogékszer felhelyezés', price: '20000', unit: 'Ft'},
            {name: 'Rendelői fogfehérítés (Pola Office +)', price: '75000', unit: 'Ft/ív'},
            {name: 'Otthoni fogfehérítés sínnel, fogfehérítővel', price: '55000', unit: 'Ft/ív'},
          ],
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/main";

.price_card {
  width: 75%;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 90%;
  }
}

.grouped {
  font-style: italic;
  font-weight: bold;
}

.price {
  font-style: italic;
}

.guarantee {
  font-weight: bold;
}

.bpld {
  font-weight: bold;
}
</style>
